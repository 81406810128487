<template>
  <div :key="counst">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px" style="padding-bottom: 0;">
        <ayl-search-box :query="table.query" :search-data='searchData' @on-search-change='onSearchChange'>
        </ayl-search-box>
      </div>
      <div class="content-main mb24px">
        <div class="accounting-data" v-for="(item,idx) in accountingData" :key="idx">
          <span>{{statisticsMap[item.value]||0}}</span>
          <span>{{item.label}}</span>
        </div>
      </div>
      <div class="content-main">
        <ayl-table-with-query-object :table="table" style="width: 100%"></ayl-table-with-query-object>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        counst: 0,
        nav: [
          {name: '订单核算'}
        ],
        searchData: [{
          type: 'dateSection_zl',
          title: '查询日期',
          // 是否需要快捷选项
          isOptions: true,
          modelName: ["begin","end"],
        }, {
          type: 'select',
          title: '业务类型',
          model: 'orderType',
          placeholder: '请选择',
          option: vm.$enums.OrderType.list
        }, {
          type: 'input',
          model: 'queryContent',
          placeholder: '客户/结算客户/驾驶员'
        }],
        table: {
          // data: null,
          api: vm.$api.OrderList,
          query: {
            type: 4,
            begin: new Date().setTime(new Date(new Date().setMonth(new Date().getMonth() - 1))),
            end: new Date().getTime(),
            orderType: '',
            carBlong: null,
            queryContent: '',
          },
          // 表格查询的条件组件
          columns: [{
            title: '运输日期',
            key: 'transportTime',
            filter: 'str2ymd',
            width: '100px',
            fixed: "left",
          }, {
            title: '提货车号',
            key: 'plateNumber',
            width: '90px',
            fixed: "left",
          }, {
            title: '驾驶员',
            key: 'driverName',
            width: '90px',
            fixed: "left",
          }, {
            title: '客户名称',
            key: 'partnerName',
            width: '100px',
            fixed: "left",
            showTooltip: true,
          }, {
            title: '结算客户',
            key: 'partnerAccountName',
            width: '100px',
            fixed: "left",
            showTooltip: true,
          }, {
            title: '业务类型',
            width: '100px',
            fixed: "left",
            render (h, ctx) {
              return h('span', [
                h('span', {
                  style: {
                    color: ctx.row.orderType === '直拨' ? vm.$config.color_z : ctx.row.orderType === '内转' ? vm.$config.color_n : vm.$config.color_w
                  }
                }, ctx.row.orderType || '- -')
              ])
            }
          }, {
            title: '起运地',
            key: 'sendAddressDetail',
            width: '80px',
            showTooltip: true,
          }, {
            title: '目的地',
            key: 'receiveAddressDetail',
            width: '80px',
            showTooltip: true,
          }, {
            title: '拼车费',
            key: 'carpoolingFee',
          }, {
            title: '收入',
            key: 'income',
          }, {
            title: '吊装费',
            key: 'liftingFee',
          }, {
            title: '过磅费',
            key: 'weightFee',
          }, {
            title: '卸车费',
            key: 'unloadFee',
          }, {
            title: '操作',
            width: '60px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goAccounting.bind(this, ctx.row)
                  }
                }, '核算'),
              ])
            }
          }]
        },
        accountingData: [
          {
            value: 'orderNum',
            label: '待核算订单数'
          }, {
            value: 'receivable',
            label: '应收金额(元)'
          }, {
            value: 'income',
            label: '收入(元)'
          }, {
            value: 'pay',
            label: '代垫费用(元)'
          }
        ],
        statisticsMap: {
          income: 0,
          orderNum: 0,
          pay: 0,
          receivable: 0
        }
      }
    },
    // /examine/bus-accounting
    methods: {
      goAccounting (e) {
        this.$router.push({
          path: '/order/bus-accounting',
          query: {
            orderId: e.orderId,
            activePath: '/order/order-accounting'
          }
        })
      },
      async onSearchChange (e) {
        await this.$search(this.table)
        if (!this.table.data[0]){
          this.statisticsMap = {}
        }else{
          this.statisticsMap = this.table.data[0] && this.table.data[0].statisticsMap
        }

      }
    },
    watch: {
      // 'table.data': {
      //    handler: async function(newName) {
      //     await this.$search(this.table)
      //     if (!this.table.data[0]) return
      //     this.statisticsMap = this.table.data[0] && this.table.data[0].statisticsMap
      //   },
      //   deep: true
      // },
      async '$route' () {
        await this.$search(this.table)
      }
    },
    beforeRouteEnter (to, from, next) {
      if (from.path === '/order/bus-accounting') {
        to.meta.isBack = true
      } else {
        to.meta.isBack = false
      }
      next()
    },
    async activated () {
      if (!this.$route.meta.isBack) {
        this.counst++
        await this.$search(this.table)
      }else{
          await this.$search(this.table)
          this.$route.meta.isBack = false // 重置详情页标识isBack
      }
    },
    // destroyed: function () {
    //     this.$route.meta.isBack = false
    // },
    async mounted () {

      // console.log(this.statisticsMap)
    }
  }
</script>

<style lang='sass' scoped>
  .content-main
    display: flex
</style>
